<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__body">
      <div class="form__item">
        <InputComponent
            v-model="form.login"
            :mask="'+7 (###) ###-##-##'"
            title="Номер телефона"
            :disabled="loading"
            @focus="focusHandler"
        />
      </div>
      <div v-if="registered" class="form__item">
        <InputComponent
            v-model="form.password"
            title="Пароль"
            :disabled="loading"
            type="password"
        />
      </div>
      <div class="form__item">
        <CheckboxComponent @input="showModal" v-model="form.terms">
          <span> Я согласен на обработку персональных данных </span>
        </CheckboxComponent>
      </div>
      <div v-if="error || timer > 0 && registered" class="form__errors">
        <template v-if="error">
          <div class="form__error">{{ error }}</div>
          <div class="form__error" v-if="!registrationAlreadyCompleted">
            Если сообщение утеряно, обратитесь в контакт-центр по номеру: <br>
            <a :href="`tel:${$store.state.config.contact_center}`">
              {{ $store.state.config.contact_center | vMask("+# (###) ###-##-##") }}
            </a>
          </div>
        </template>
        <template v-if="timer > 0 && registered">
          <div class="form__message">Код подтверждения отправлен</div>
          <div class="form__message">Повторная отправка кода будет доступна через: {{ timer }} секунд</div>
        </template>
      </div>
    </div>
    <div class="form__actions">
<!--      <button-->
<!--        v-if="registered && !isPasswordChanged"-->
<!--        @click="resendCode"-->
<!--        class="btn-main btn-auto"-->
<!--        type="button"-->
<!--        :disabled="timer > 0"-->
<!--      >-->
<!--        <template>Отправить код еще раз</template>-->
<!--      </button>-->
      <button class="btn-main" :disabled="!form.terms" type="submit">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";
import CheckboxComponent from "@/components/form/CheckboxComponent.vue";
import TermsModal from "@/components/modals/components/TermsModal.vue";

export default {
  name: "BeginRegistration",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
      timer: 0,
      interval: null,
      registered: false,
      needPassword: false,
      registrationAlreadyCompleted: false,
      form: {
        login: null,
        password: null,
        terms: null,
      }
    }
  },
  methods: {
    showModal() {
      this.$nextTick(() => {
        if (this.form.terms) {
          this.form.terms = false;
          this.$store.state._modals.push({
            component: TermsModal,
            options: {
              callback: () => {
                this.form.terms = true;
              }
            }
          })
        }
      })
    },
    focusHandler() {
      if (!this.form.login) {
        this.form.login += " "
      }
    },
    resendCode() {
      this.$store.dispatch("SEND_CONFIRM_CODE", {
        data: { phoneNumber: this.form.login },
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data.result && data.result.state === "Success") {
            this.timer = this.$store.state.config.code_timer
            this.countdown()
          } else if (data.result && data.result.state === "Error") {
            this.error = data.result.message
          }
        })
    },
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
		tryFinishRegistration() {
			this.$store.dispatch("TRY_FINISH_REGISTRATION", {
				token: localStorage.getItem("auth-token")
			}).then(({ data }) => {
				this.$emit('changeStep', this.codes.FINISH_CODE)
			})
		},
    formSubmit() {
      if (this.loading || !this.form.terms) {
        return;
      }

      if (!this.loading) {
        this.loading = true
        this.registrationAlreadyCompleted = false;
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        this.$store.dispatch("BEGIN_REGISTRATION", {
          // login: this.form.login ? "7" + this.form.login.replace(/[^\d]/g, '') : null,
          login: this.form.login ? this.form.login.replace(/[^\d]/g, '') : null,
          password: this.form.password,
        })
            .then(({data}) => {
              // если введенный номер еще не зарегистрирован, то на него отправляется сообщение
              let token = data && data.data && data.data.authToken ? data.data.authToken : null
              if (data.result && data.result.state === "Success" && token) {
                // сохраняем полученный токен
                this.$store.dispatch('setAuthToken', { token: token })
                // получение шагов регистрации
                this.$store.dispatch('REGISTRATION_ACTIONS', { token: token }).then(() => {
                  if (this.form.password) {
                    // проверка, какой шаг пропущен и возврат на него
                    if (this.$store.state.registration_actions.find(item => item.userActionType === "PasswordRequired").isDone) {
                      let questionsAction = this.$store.state.registration_actions.find(item => item.userActionType === "Questions")
                      if (questionsAction && questionsAction.isDone) {
                        this.tryFinishRegistration()
                      } else {
                        this.$store.dispatch('GET_QUESTIONS', {
                          token: localStorage.getItem("auth-token")
                        }).then(() => {
                          this.$emit("changeStep", this.codes.QUESTIONS_CODE)
                        })
                      }
                    } else {
                      this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
                    }
                  } else {
                    this.$store.state.user.phoneNumber = this.form.login.replace(/[^\d]/g, '')
                    this.$emit("changeStep", this.codes.PHONE_CONFIRM_CODE)
                  }
                })
              } else if (data.result && data.result.state === "Error") {
                // сохраняем номер телефона
                this.$store.state.user.phoneNumber = this.form.login.replace(/[^\d]/g, '')
                if (data.data && data.data.state === "RegistrationAlreadyCompleted") { // Данный номер уже зарегистрирован
                  // this.$router.push({ name: "bonuscards" }) // если хотим начать регистрацию бонусной карты
                  this.$emit("changeStep", this.codes.CARD_ALREADY_REGISTERED)
                  this.error = "Данный номер уже зарегистрирован."
                  this.registrationAlreadyCompleted = true
                } else if (data.data && data.data.state === "PasswordRequired") {
                  this.error = "Введите код подтверждения из SMS, полученной при первой попытке регистрации"
                  this.registered = true
                } else if (data.data && data.data.state === "NeedEnterAuthenticationCode") {
                  // Если пользователь прервал регистрацию и возвращается в систему для регистрации, то перенаправляем его на ContinueRegistration
                  this.$emit("changeStep", this.codes.CONTINUE_REGISTRATION)
                } else {
                  // this.error = data.result.message
                  this.error = "Введены неправильные данные"
                }
              } else if (data.result && data.result.state === "ValidationError") {
                this.error = "Ошибка. Введите данные"
              }
              this.loading = false
            })
            .catch(() => {
            });
      }
    }
  },
  components: {
    CheckboxComponent,
    InputComponent,
    LoadingIndicator,
  }
}
</script>
